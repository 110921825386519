<template>
  <v-app
    style="background-color: black; overflow: hidden"
    :style="
      'background-color: black; height: ' + viewportHeight + 'px; width: 100%;'
    "
  >
    <v-main>
      <v-container
        fluid
        class="pa-0 ma-0 flex"
        style="
          'background-color: green; height: 100%; width: 100%;'
        "
      >
        <v-row no-gutters class="fill-height flex" align="center">
          <div id="novo-remote-media-div"></div>
        </v-row>
      </v-container>
    </v-main>

    <div style="position: fixed; top: 20px; right: 20px" id="local"></div>
    <v-footer app color="transparent">
      <v-row class="py-5" justify="center" align="center" no-gutters>
        <v-btn
          class="mx-2"
          :color="localVideoOn ? 'blue lighten-1' : 'grey darken-2'"
          @click="toggleLocalVideo"
          fab
          dark
          ><v-icon v-if="localVideoOn">mdi-video</v-icon
          ><v-icon v-else>mdi-video-off</v-icon></v-btn
        >
        <v-btn @click="endCall" class="mx-2" x-large dark color="red" fab
          ><v-icon size="xl">mdi-close</v-icon></v-btn
        >
        <v-btn
          :color="localMicrophoneOn ? 'blue lighten-1' : 'grey darken-2'"
          class="mx-2"
          @click="toggleLocalMicrophone"
          fab
          dark
          ><v-icon v-if="localMicrophoneOn">mdi-microphone</v-icon
          ><v-icon v-else>mdi-microphone-off</v-icon></v-btn
        >
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { /* @vite-ignore */ connect } from "twilio-video";

export default {
  name: "App",

  components: {},

  data: () => ({
    localVideoOn: false,
    localMicrophoneOn: false,
    roomToken: null,
    videoRoom: null,
    initializingVideoRoom: false,
    viewportHeight: 0,
  }),
  methods: {
    endCall() {
      if (this.videoRoom && this.videoRoom.localParticipant) {
        this.videoRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
        });
        this.videoRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
        });
      }

      if (this.videoRoom) {
        this.videoRoom.disconnect();
      }
    },
    toggleLocalVideo() {
      if (this.localVideoOn) {
        this.videoRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable();
          this.localVideoOn = false;
        });
      } else {
        this.videoRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable();
          this.localVideoOn = true;
        });
      }
    },
    toggleLocalMicrophone() {
      if (this.localMicrophoneOn) {
        this.videoRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
          this.localMicrophoneOn = false;
        });
      } else {
        this.videoRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
          this.localMicrophoneOn = true;
        });
      }
    },
  },
  async mounted() {
    this.viewportHeight = window.innerHeight;

    window.addEventListener("resize", () => {
      console.log("resizeing");
      this.viewportHeight = window.innerHeight;
    });

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });
    //http://192.168.1.10:8081/?accessToken=eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJleHAiOjE2NjE2NjE0OTAsImp0aSI6IlNLOTYxYjNkNjhjYWI4MjRlZTllZDA3YTk1MTJlNzBmZjUtMTY2MTY1Nzg5MCIsImlhdCI6MTY2MTY1Nzg5MCwiaXNzIjoiU0s5NjFiM2Q2OGNhYjgyNGVlOWVkMDdhOTUxMmU3MGZmNSIsIm5iZiI6MTY2MTY1Nzg5MCwic3ViIjoiQUNiMGM3NDZiM2QzOGQ5YWI5ZGY1ZjdlODM5ZTkwODk2ZSIsImdyYW50cyI6eyJpZGVudGl0eSI6Ikludml0ZWUiLCJ2aWRlbyI6eyJyb29tIjoiUk02MTY0MGJkYzYxZmJkNTEyYmE5MGE4YmEwZGYxNmNiYSJ9fX0.dNIZQFdMF1cTjdY_gr-PKWVSH6RGvYe1mVV4XUdzheE
    //http://localhost:8081/?eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJleHAiOjE2NjE2NjQwNjEsImp0aSI6IlNLOTYxYjNkNjhjYWI4MjRlZTllZDA3YTk1MTJlNzBmZjUtMTY2MTY2MDQ2MSIsImlhdCI6MTY2MTY2MDQ2MSwiaXNzIjoiU0s5NjFiM2Q2OGNhYjgyNGVlOWVkMDdhOTUxMmU3MGZmNSIsIm5iZiI6MTY2MTY2MDQ2MSwic3ViIjoiQUNiMGM3NDZiM2QzOGQ5YWI5ZGY1ZjdlODM5ZTkwODk2ZSIsImdyYW50cyI6eyJpZGVudGl0eSI6Ikludml0ZWUiLCJ2aWRlbyI6eyJyb29tIjoiUk0wMjE5MzhkNWE1M2U2YzVmNmEwM2QyY2FmZmRlNGI5MiJ9fX0.1MOjTfJh5Xo79NLkyOJHgDP18IoRQjbY0s5dohLdlRg
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");

    const vm = this;

    if (accessToken) {
      this.initializingVideoRoom = true;
      this.roomToken = accessToken;
      this.videoRoom = await connect(accessToken);
      this.initializingVideoRoom = false;
      this.videoRoom.localParticipant.videoTracks.forEach((publication) => {
        let video = document.getElementById("local");
        video.appendChild(publication.track.attach());
        video.lastChild.style.width = "15vw";
        video.lastChild.style.maxWidth = "150px";
        video.lastChild.style.minWidth = "100px";
        video.lastChild.style.borderRadius = "4px";
        video.lastChild.style.border = "1px solid white";
        video.lastChild.style.padding = "0px";
        video.lastChild.style.marginTop = "5px";
        video.lastChild.style.transform = "scale(-1, 1)";

        this.localVideoOn = true;

        this.localMicrophoneOn = true;
      });

      this.videoRoom.localParticipant.audioTracks.forEach((publication) => {
        console.log("there is an audio track", publication);
      });

      this.videoRoom.participants.forEach((participant) => {
        let vidContainer = document.getElementById("novo-remote-media-div");
        if (vidContainer) {
          while (vidContainer.firstChild) {
            vidContainer.removeChild(vidContainer.firstChild);
          }
        }

        participant.tracks.forEach((publication) => {
          if (publication.track) {
            console.log("adding tracks already shared.");

            document
              .getElementById("novo-remote-media-div")
              .appendChild(publication.track.attach());
            document.getElementById(
              "novo-remote-media-div"
            ).lastChild.className = "novoHealthVideoView";
          }
        });

        participant.on("trackSubscribed", (track) => {
          console.log("adding tracks already shared.");
          document
            .getElementById("novo-remote-media-div")
            .appendChild(track.attach());
          document.getElementById("novo-remote-media-div").lastChild.className =
            "novoHealthVideoView";
        });
      });

      this.videoRoom.on("participantConnected", (participant) => {
        // you will always be the first in the room
        console.log(`Participant "${participant.identity}" connected`);
        console.log("handling newly connected participants.");
        vm.inviteeConnected = true;
        vm.$emit("inviteeJoined");

        participant.tracks.forEach((publication) => {
          if (publication.track) {
            console.log("adding tracks already shared.");

            document
              .getElementById("novo-remote-media-div")
              .appendChild(publication.track.attach());
            document.getElementById(
              "novo-remote-media-div"
            ).lastChild.className = "novoHealthVideoView";
          }
        });

        participant.on("trackSubscribed", (track) => {
          console.log("adding tracks just now shared.");

          document
            .getElementById("novo-remote-media-div")
            .appendChild(track.attach());
          document.getElementById("novo-remote-media-div").lastChild.className =
            "novoHealthVideoView";
          vm.resizeObserver.observe(
            document.getElementById("novo-remote-media-div")
          );
        });

        this.videoRoom.on("disconnected", (room) => {
          // Detach the local media elements
          room.localParticipant.tracks.forEach((publication) => {
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
          });
          this.videoRoom = null;
        });
      });
    } else {
      console.log("no access token");
    }
  },
};
</script>

<style>
.novoHealthVideoView {
  max-height: 100vh;
  max-width: 100vw;
  min-width: 100vw;
  object-fit: contain;
}
.container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.participant {
  margin-bottom: 5px;
  margin-right: 5px;
}
.participant div {
  text-align: center;
}
.participant div:first-child {
  width: 240px;
  height: 180px;
  background-color: #ccc;
  border: 1px solid black;
}
.participant video {
  width: 100%;
  height: 100%;
}
</style>
